import { GetStaticProps } from 'next';

import { HeaderBlock } from '@/blocks/header-block';
import { PageLayout } from '@/components/layouts/page-layout';
import { SITE_NAME } from '@/lib/constants';
import { SeoMetaTagType } from '@/types';
import { PostSnippets } from '@/types/cms-types';
import { cmsPostService } from '@/utils/api/services/cms.service/post';

const ErrorPage = ({ posts }: { posts?: PostSnippets }) => {
  const metaTags = [{ content: `Page not found - ${SITE_NAME}`, attributes: null, tag: 'title' }] as SeoMetaTagType[];

  return (
    <PageLayout metaTags={metaTags} posts={posts}>
      <div className="blocks">
        <HeaderBlock
          id="error-page-header-block"
          heading="Page not found"
          body={`<p>Seems the page you're looking for doesn't exist, could be our fault, could be your fault, but there is little benefit in pointing fingers, we're all friends here.</p> <p>Let's try and get you where you need to be.</p>`}
          variant="dark"
          buttons={[
            {
              text: 'Go to the home page',
              href: '/',
              iconLeft: 'arrow-left-alt',
              variant: 'secondaryDark',
              externalLink: '',
            },
          ]}
        />
      </div>
    </PageLayout>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const posts = await cmsPostService.getPostSnippetsCached();

  return {
    props: { posts },
  };
};

export default ErrorPage;
